<template>
  <div class="flex-auto column" style="margin-bottom: 0;">
    <div class="padding-bottom-10 border-bottom bold">修改密码</div>
    <el-form :model="passwordForm" ref="passwordForm" label-width="auto" style="margin-top: 15px">
        <el-form-item label="原密码"  prop="oldPassword"
          :rules="[{ required: true, message: '此项不能为空'}]"
        >
          <el-input v-model.trim="passwordForm.oldPassword" type="password" autocomplete="off" placeholder="请输入原密码" maxlength="20" ></el-input>
        </el-form-item>
        <el-form-item>
          <div class="font-size-12 color-sub" style="line-height: 1.5em; word-break: normal;">密码必须是6-20位组合密码，至少包含字母、数字、字符中的两种</div>
        </el-form-item>
        <el-form-item label="新密码"  prop="newPassword"
        :rules="[{ required: true, message: '此项不能为空'}]"
        >
          <el-input v-model.trim="passwordForm.newPassword"  type="password" autocomplete="off" placeholder="请输入新密码"  maxlength="20"></el-input>
        </el-form-item>
        <el-form-item label="确认新密码"  prop="newPassword2"
        :rules="[{ required: true, message: '此项不能为空'}]"
        >
          <el-input v-model.trim="passwordForm.newPassword2"  type="password" autocomplete="off" placeholder="再次输入新密码" maxlength="20" ></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" :loading="loading" size="medium" @click="submitPasswordForm('passwordForm')">修改密码</el-button>
        </el-form-item>
      </el-form>
      
  </div>
</template>

<script>

export default {
  components: { },
  name: 'ChangePassWord',
  data() {
    return {
      loading:false,
      passwordForm:{
        oldPassword:'',
        newPassword:'',
        newPassword2:'',
      },
    }
  },
  computed:{
    
  },
  methods:{
    //修改密码
    submitPasswordForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.changePassword()
        } else {
          return false;
        }
      });
    },
    changePassword(){
      if(this.passwordForm.newPassword<6){
        this.$message({message: '新密码格式为6位以上至少包含数字、字母和符号的两种组合',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false;
      }else if(this.passwordForm.newPassword!=this.passwordForm.newPassword2){
        this.$message({message: '新密码两次输入不一致',type:'warning',center:true,offset: Math.ceil(document.documentElement.clientHeight/2.4)})
        return false;
      }
      this.loading=true
      let params={
            ...this.$store.state.basicParams,
            old_pwd:this.passwordForm.oldPassword,
            new_pwd: this.passwordForm.newPassword,
          }
      this.$instance.post('/user/change_pwd',this.$qs.stringify(params))
      .then(res=>{
        this.loading=false
        if(res.data.code==0){
          this.$store.commit('stateInit')
          try{
            localStorage.removeItem("loginRes");
          }catch{
            //
          }
          this.$router.replace({
            path: '/login',
          })
          this.$alert('密码修改成功，请重新登录。', '提示', {
            customClass:"custom-msgbox",
            confirmButtonText: '确定',
            showClose:false,
            type: 'warning'
          })
          .then(() => {
            
          })
          
        }
      })
      .catch(()=> {
        this.loading=false
      }); 
    },
   
  },
  created(){

  },
  mounted(){ 
    
  },
  beforeDestroy() {
   
  },
  watch: {
    
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
</style>
<style>

</style>
